import './App.css';
import TopBanner from './layout/TopBanner';
import Header from './layout/Header';
import Home from './components/home/Home';
import SubjectFooter from './layout/SubjectFooter';
import UsefulLinksFooter from './layout/UsefulLinksFooter';
import Footer from './layout/Footer';
import { Route, Routes } from 'react-router-dom';
import About from './components/about/About';
import PostList from './components/post/PostList';

function App() {
  return (<div className="mainWrapper">
    <TopBanner />
    <Header />
    
    <Routes>
      <Route path="/home-page" element={<Home />} />
      <Route path="/about-page" element={<About />} />
      <Route path="/post-list/:id" element={<PostList />} />
      <Route path="*" element={<Home />} />
    </Routes>

    <SubjectFooter />
    <UsefulLinksFooter />
    <Footer/>
  </div>
   
  );
}

export default App;
