import { Link } from "react-router-dom";

function TopBanner() {
    return (<>
        <div id="topBanner">
            <Link to="/home-page" id="logoContainerLink">
                <span>
                    <img src="icons/L2P_Logo.png" id="logo" />
               </span>
                <span id="templateTitle">Love2Programming</span>
            </Link>
            <a href="#" className="btnRegister">Register</a>
            <span className="btnLoginRegisterSeparator">|</span>
            <a href="#" className="btnLogin">Login</a>
            <div className="search-box-container">
                <form action="">
                    <input type="button" name="btnSearch" className="btnSearch" value="Search"/>
                        <input style={{height:"15px"}} type="text" name="txtSearch" className="txtSearch" placeholder="Search tutorials..." />
                </form>
            </div>
        </div>
    </>);
}

export default TopBanner;