function UsefulLinksFooter() {
    return (<div id="usefulLinksFooterContainer">
        <div id="usefulLinksFooter">
            <div id="useFullLinksSubContainer">
                <h2>Love2Programming</h2>
                <p><b>Phone: </b>+93774346205</p>
                <p><b>Email: </b>obaidullahkawsar@gmail.com</p>
            </div>
            <div id="useFullLinksSubContainer">
                <h2>Useful Links</h2>
                <ul>
                    <li><a href="default.html">○ Home</a></li>
                    <li><a href="aboutus.html">○ About us</a></li>
                    <li><a href="contactus.html">○ Contact us</a></li>
                    <li><a href="#">○ Privacy Policy</a></li>
                </ul>
            </div>
            <div id="useFullLinksSubContainer">
                <h2>Share this</h2>
                <a href="" className="shareLinks">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-facebook icon-color">
                        <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z">
                        </path>
                    </svg>
                </a>
                <a href="" className="shareLinks">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-twitter icon-color">
                        <path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z">
                        </path>
                    </svg>
                </a>
                <a href="" className="shareLinks">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-phone icon-color">
                        <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z">
                        </path>
                    </svg>
                </a>
            </div>
        </div>
    </div>
    );
}

export default UsefulLinksFooter;