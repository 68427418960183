function About() {
    return (<div className="contentArea" id="contentArea"
        style={{
        padding: "0px", height: "auto",
        gridColumn: "span 4",
        gridRow: "3"
    }}>

        <h2 className="aboutName">Love2Programming</h2>
        <h2 className="aboutJob">The best place to learn coding</h2>
        <div className="controlGroup">
            <img className="aboutImage" src="icons/L2P_Logo.png" />
        </div>
        <div className="aboutInformation">
            <h1>About us</h1>
            <p>Our hard-working team is trying to provide the best content in many programming languages like Java, Dot Net, PHP, and more, in Databases like Microsoft SQL Server, Oracle, MySQL, and more, in Web Designing like HTML, CSS, JavaScript, Bootstrap, and more.<br />
                Our primary goal is to provide simple, step-by-step, and easier understandable tutorials with real-world examples for
                beginners as well as professional programmers and developers.
            </p>

        </div>
    </div>  );
}

export default About;