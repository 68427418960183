import { Link } from "react-router-dom";
import { useStoreContext } from "../app/api/context/StoreContext";

function SubjectFooter() {

    const { categories } = useStoreContext();

    return (<>
        <div id="subjectFooter" >
            <ul>
                {categories.map((category, categoryIndex) => {
                    return (<span key={categoryIndex}>
                        {
                            category.subjects.map((subject, subjectIndex) => {
                                return (<li key={subjectIndex}>
                                    <Link to={`/post-list/${subject.id}`}>{subject.subjectName}</Link>
                                </li>)
                            })
                        }
                    </span>)
                })}
            </ul>
        </div>
    </>);
}

export default SubjectFooter;