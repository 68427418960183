import { useEffect } from "react";
import { Link } from "react-router-dom";
import agent from "../app/api/agent";
import { useStoreContext } from "../app/api/context/StoreContext";



function Header() {

    const { categories, setCategories } = useStoreContext();

    useEffect(() => {
        agent.Category.list().then(response => {
            setCategories(response)
        }).catch(error => {
            console.log(error);
        }).finally(() => {
        });
    },[])
    return (<header>
        <div className="container">
            <input type="checkbox" name="" id="check" />
            <div className="nav-btn">
                <div className="nav-links">
                    <ul>
                        <li className="nav-link" style={{ "--i": ".6s" }}>
                            <Link to="/home-page" className="navbar-brand">Home</Link>
                        </li>
                        <li className="nav-link" style={{ "--i": ".6s" }}>
                            <Link to="/about-page">About</Link>
                        </li>
                        {categories.map((category, categoryIndex) => {
                            return (<li key={categoryIndex} className="nav-link" style={{ "--i": ".85s" }}>
                                <a href="#">{category.categoryName} ▼<i className="fas fa-caret-down"></i></a>
                                <div className="dropdown">
                                    <ul>
                                        {
                                            category.subjects.map((subject, subjectIndex) => {
                                                return (<li key={subjectIndex} className="dropdown-link">
                                                    <Link to={`/post-list/${subject.id}`}>{subject.subjectName}</Link>
                                                </li>)
                                            })
                                       }
                                        <div className="arrow"></div>
                                    </ul>
                                </div>
                            </li>)
                        })}
                        <li className="nav-link" style={{ "--i": "1.35s" }} >
                            <a href="contactus.html">Contact</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div className="web-menu-container">
                <div className="web-menu">
                    <div></div>
                </div>
            </div>
        </div>
    </header>);
}

export default Header;