function Home() {
    return ( 

        <div className="contentArea" id="contentArea"
            style={{
            padding: "0px", height: "auto",
            gridColumn: "span 4",
            gridRow: "3"}}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1300 320"><path fill="#24272b" fillOpacity="1" d="M0,256L40,261.3C80,267,160,277,240,256C320,235,400,181,480,170.7C560,160,640,192,720,202.7C800,213,880,203,960,186.7C1040,171,1120,149,1200,154.7C1280,160,1360,192,1400,208L1440,224L1440,0L1400,0C1360,0,1280,0,1200,0C1120,0,1040,0,960,0C880,0,800,0,720,0C640,0,560,0,480,0C400,0,320,0,240,0C160,0,80,0,40,0L0,0Z"></path>
                <text x="20" y="60" fontSize="36px" fontWeight="bold" fill="#bde0fe">Begin Learning with Love2Programming</text>
                <text x="20" y="100" fontSize="22px" fill="#eae2b7">With Love2Programming, you can learn programming in simple and real-life examples.</text>
                <text x="20" y="130" fontSize="22px" fill="#eae2b7">We provide the best quality information and understandable articles for developers and</text>
                <text x="20" y="160" fontSize="22px" fill="#eae2b7">programmers to get more knowledge in programming.</text>
                <text x="1000" y="150" className="keywords">{ }</text>
                <text x="1100" y="110" className="keywords">[ ]</text>
                <text x="1230" y="50" className="keywords">( )</text>
                <text x="1070" y="50" className="keywords">switch</text>
                <text x="1000" y="50" className="keywords">if</text>
                <text x="1180" y="140" className="keywords">while</text>
                <text x="1000" y="100" className="keywords">&lt;/&gt;</text>
                <text x="1240" y="90" className="keywords">.</text>
                <text x="1180" y="90" className="keywords">;</text>
            </svg>

            <div className="homePageSubject">
                <div className="box1">
                    <span className="subjectTitle">Java Tutorial</span>
                    <a href="#" className="subjectLink">Start Java</a>
                </div>
                <div className="box1">
                    <span className="subjectTitle">SQL Server Tutorial</span>
                    <a href="#" className="subjectLink">Start SQL Server</a>
                </div>
                <div className="box1">
                    <span className="subjectTitle">HTML Tutorial</span>
                    <a href="#" className="subjectLink">Start HTML</a>
                </div>
                <div className="box1">
                    <span className="subjectTitle">CSS Tutorial</span>
                    <a href="#" className="subjectLink">Start CSS</a>
                </div>
            </div>

        </div>
     );
}

export default Home;