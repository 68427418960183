import axios from "axios";
//axios.defaults.baseURL = "https://localhost:7164/api/";

axios.defaults.baseURL = "https://love2programming.com/api/";

const responseBody = (response) => response.data;


const sleep = () => new Promise(resolve => setTimeout(resolve, 100));
axios.interceptors.response.use(async response => {
    //await sleep();


    return response;
}, (error) => {
    const { data, status } = error.response;

    switch (status) {
        case 400:
            alert(data.title);

            break;
        case 401:
            alert(data.title);
            break;
        case 403:
            alert(data.title);
            break;
        case 500:
            alert(data.title);
            break;
        default:
            break;
    }
    return Promise.reject(error.response);
});


const requests = {
    get: (url) => axios.get(url).then(responseBody),
    post: (url, body) => axios.post(url, body).then(responseBody),
    put: (url, body) => axios.put(url, body).then(responseBody),
    delete: (url) => axios.delete(url).then(responseBody)
};

const Category = {
    list: () => requests.get(`categories/getCategoriesWithSubjects`),
};


const Subject = {
    list: (id) => requests.get(`subjects/getSubjectWithPosts/${id}`),
};

const Post = {
    getPost: (id) => requests.get(`posts/getPost/${id}`),
};



const agent = {
    Category,
    Subject,
    Post
};

export default agent;