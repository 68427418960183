import { createContext, useContext, useState } from "react";


export const StoreContext = createContext(undefined);

export function useStoreContext() {
    const context = useContext(StoreContext);

    if (context === undefined)
        throw Error("Oopps we do not seem to be inside the provider")

    return context;
}

export function StoreProvider({ children }) {
    const [categories, setCategories] = useState([]);

    return (<StoreContext.Provider value={{ categories, setCategories }}>
        {children}
    </StoreContext.Provider>);
}