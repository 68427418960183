import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import agent from "../../app/api/agent";

function PostList(props) {
    const { id } = useParams(props);
    const [subjects, setSubjects] = useState([]);

    const [post, setPost] = useState({});

    useEffect(() => {
        agent.Subject.list(id).then(response => {
            setSubjects(response);

            if (response[0].posts[0] !== undefined)
                handleClick(response[0].posts[0].postId);
            else
                setPost({});
           
        }).catch(error => {
            console.log(error);
        }).finally(() => { });
    }, [id]);



   
    function handleClick(id) {
        agent.Post.getPost(id).then(response => {
            setPost(response);
        }).catch(error => {
            console.log(error);
        }).finally(() => {

        });
    }



    return (<>
        <div id="contentSidebar">
            {subjects.map((subject, subjectIndex) => {
                return (<div key={subjectIndex}>
                    <span  id="subjectName">{subject.subjectName}</span>
                    <ul id="topicTitle">
                        {
                            subject.posts.map((post, postIdex) => {
                                return (<a onClick={(data)=>handleClick(post.postId)} key={postIdex} href="#"><li>{post.postTitle }</li></a>)
                            })
                        }
                    </ul>
                </div>)
            })}
        </div>

        <div className="contentArea" id="contentArea">
            {post !== undefined && <div dangerouslySetInnerHTML={{ __html: post.postDescription }} />}
            <div className="comment-form-container">
                <h2 className="blankPageTitle"> Leave a Reply</h2>
                <p>Your email address will not be published. Required fields are marked:</p>
                <div className="formRow">
                    <form action="">
                        <div className="formGroup">
                            <textarea rows="10" placeholder="Comment"></textarea>
                        </div>
                        <br/>
                            <div className="formGroup">
                                <input type="text" name="" className="roundedInput" placeholder="Name" />
                                <input type="password" name="" className="roundedInput" placeholder="Email" />
                            </div>

                            <div className="formGroup">
                                <input type="submit" value="Post Comment" className="btnSendMessage" />
                            </div>
                    </form>
                </div>
            </div>

            <div className="comment-image-wrapper">
                <img src="icons/userIcon.jpeg"/>
                    <b>Mohammad Omer</b>
                    <span>SEPTEMBER 13, 2019 | 9:00 PM</span>
                    <p>It is a best site to learn oops concept in very simple forms with suitable examples.And the content of this sites is easy to understand for learners.</p>
            </div>

            <div className="comment-image-wrapper">
                <img src="icons/userIcon.jpeg"/>
                    <b>Hamza</b>
                    <span>SEPTEMBER 13, 2019 | 6:00 AM</span>
                    <p>I left all my books by seeing this website too good for beginners
                        way of examples and explanations were too good that everyone can understand it
                        mean while fix bugs at index sheet maintenance</p>
            </div>
        </div>

        <div id="advertiseSidebar">
            <h1>Adv1</h1>
            <h1>Adv2</h1>
        </div>
    </>);
}

export default PostList;